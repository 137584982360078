import React, { createContext, useState } from "react";

export const UserDisplayContext = createContext();

export const UserDisplayProvider = ({ children }) => {
    const [userDisplay, setUserDisplay] = useState("default value");

    return (
        <UserDisplayContext.Provider value={{ userDisplay, setUserDisplay }}>
            {children}
        </UserDisplayContext.Provider>
    );
};
