import React from "react";
import "./experience.css";

function Experience() {
    return (
        <div className="experience">
            <h2 className="subpage-title">Experience</h2>
            <div className="text-box">
                <ul>
                    <li>
                        <h3>Software Engineer</h3>
                        <h5>Netcracker Technology</h5>
                        <h6>October 2023 - Present</h6>
                        <p>
                            Inside the Digital BSS team, I work as a Front-end Developer in projects like e-commerces,
                            CRMs and ERPs. React, Redux, TypeScript, Angular, RxJS, NgRx, Java, and GraphQL are the main
                            technologies we use for development. For version control we use Git / GitLab.
                        </p>
                    </li>
                    <hr />
                    <li>
                        <h3>Frontend Developer</h3>
                        <h5>Turn2C</h5>
                        <h6>August 2022 - September 2023</h6>
                        <p>
                            In this start-up, we developed the institutional website, two back-office CRMs using React,
                            a checkout system with Angular, and a reusable chat plugin using Vanilla Javascript. For
                            styling, we used SCSS for outer projects, and Bootstrap for the inner ones. Axios to
                            integrate with the RESTful APIs, and GIT to control the softwares versions.
                        </p>
                    </li>
                    <hr />
                    <li>
                        <h3>React Developer</h3>
                        <h5>Opah IT</h5>
                        <h6>December 2020 - August 2022</h6>
                        <p>
                            We developed the backoffice web application and the mobile application for a company in the
                            industrial sector. In this project I focused on web development with React, Styled
                            Components and Material UI. Building and integrating the screens coming from the design team
                            via Figma. I also had contact with the backend implementing integrated tests with Jest. And
                            also with mobile development, implementing tests again but this time End-to-End, using Jest
                            in React Native.
                        </p>
                    </li>
                    <hr />
                    <li>
                        <h3>Frontend Developer</h3>
                        <h5>Tecla T</h5>
                        <h6>March 2020 - December 2020</h6>
                        <p>
                            I participated in the frontend construction of three different web projects coding in HTML,
                            CSS and javascript to perform the tasks available in the Kanban board.
                        </p>
                    </li>
                    <hr />
                    <li>
                        <h3>Administrative Analyst</h3>
                        <h5>Instituto Trabalho e Vida</h5>
                        <h6>April 2015 - February 2020</h6>
                        <p>
                            Production of technical seminars within the sector of labor relations, safety and health.
                            Dissemination of events in the media, sponsorship negotiation, purchases in general,
                            organization of post-event competencies to fulfill the company's responsibilities towards
                            participants/partners and staff training to perform these functions.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Experience;
