import React from "react";
import "./about.css";

function About() {
    return (
        <div className="about">
            <h2 className="subpage-title">About</h2>
            <div className="text-box">
                <p>
                    Fluent in English and proficient with web development technologies, mainly in the Javascript
                    ecosystem.
                    <br />
                    <br />
                    More than five years of Frontend professional experience, programming in React, Angular, Typescript,
                    HTML5, CSS3, testing with Jest, and controlling code versions with Git/GitLab. Also know the most
                    used libraries like Redux for state management in React and Less or Sass for CSS code preprocessing.
                    <br />
                    <br />
                    I am habituated to building and maintaining scalable web applications for varied sectors such as
                    commercial, services, financial, and telecommunications. In my journey, I always followed the best
                    programming practices, being committed to my performance and that of the team.
                    <br />
                    <br />
                    Active communication, logical thinking, and focused problem solving are some of my principal
                    characteristics.
                </p>
            </div>
        </div>
    );
}

export default About;
