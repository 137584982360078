import Navbar from "./components/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Experience from "./pages/Experience";
import Education from "./pages/Education";
import Footer from "./components/Footer";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import { useCallback, useEffect, useState } from "react";
// import { UserDisplayContext } from "./contexts/UserDisplayContext";
import NotFound from "./pages/NotFound";

function App() {
    const [hasOverflow, setHasOverflow] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    //   const { userDisplay, setUserDisplay } = useContext(UserDisplayContext);
    const location = useLocation();

    const checkOverflow = useCallback(() => {
        const pageElement = document.querySelector(".page");
        if (pageElement) {
            setHasOverflow(pageElement.scrollHeight > window.innerHeight);
        }
    }, []);

    const checkIfAtBottom = () => {
        setIsAtBottom(window.innerHeight + window.scrollY >= document.body.offsetHeight);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("resize", checkOverflow);
        window.addEventListener("scroll", checkIfAtBottom);

        return () => {
            window.removeEventListener("resize", checkOverflow);
            window.removeEventListener("scroll", checkIfAtBottom);
        };
    }, [checkOverflow]);

    useEffect(() => {
        const arrowElement = document.querySelector(".arrow-down");
        let scrollInterval;

        const handleMouseEnter = () => {
            scrollInterval = setInterval(() => {
                if (!isAtBottom) {
                    window.scrollBy({ top: 10, behavior: "smooth" });
                } else {
                    clearInterval(scrollInterval);
                }
            }, 10);
        };

        const handleMouseLeave = () => {
            clearInterval(scrollInterval);
        };

        if (arrowElement) {
            arrowElement.addEventListener("mouseenter", handleMouseEnter);
            arrowElement.addEventListener("mouseleave", handleMouseLeave);
            arrowElement.addEventListener("touchstart", handleMouseEnter);
            arrowElement.addEventListener("touchend", handleMouseLeave);
        }

        return () => {
            if (arrowElement) {
                arrowElement.removeEventListener("mouseenter", handleMouseEnter);
                arrowElement.removeEventListener("mouseleave", handleMouseLeave);
                arrowElement.removeEventListener("touchstart", handleMouseEnter);
                arrowElement.removeEventListener("touchend", handleMouseLeave);
            }
            clearInterval(scrollInterval);
        };
    }, [hasOverflow, isAtBottom]);

    useEffect(() => {
        checkOverflow();
    }, [location, checkOverflow]);

    return (
        <div className="page">
            <Navbar />
            <div className="container" role="main">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/experience" element={<Experience />} />
                    <Route path="/education" element={<Education />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                {hasOverflow && !isAtBottom && isDesktop && (
                    <div className="arrow-down">
                        <ArrowDown />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default App;
